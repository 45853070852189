import {
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  CardHeader
} from '@chakra-ui/react';

interface MatchVersionsProps {
  versions: any[];
}

export const MatchVersions = ({ versions }: MatchVersionsProps): JSX.Element => {
  console.log('test');
  return (
    <>
      <SimpleGrid spacing={4} templateColumns="repeat(auto-fill)">
        <Card>
          <CardHeader>
            <Heading size="md">
              Latest Version: {versions && versions.length > 0 ? versions[0].version : ''}
            </Heading>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Version</Th>
                    <Th>Created On</Th>
                    <Th>File Name</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {versions &&
                    versions.map((version: any, index: number) => (
                      <Tr key={`version${index}`}>
                        <Td>{version.version}</Td>
                        <Td>{version.created_on}</Td>
                        <Td>{version.file_name}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </SimpleGrid>
    </>
  );
};
