import { useState, useEffect } from 'react';
import { Grid, GridItem, FormControl, FormLabel } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { groupBy } from 'lodash';
import { DEFAULT_SELECT } from './OverviewConstants';
import { MatchList } from './MatchList/MatchList';
import { StageList } from './StageList';
import { TournamentList } from './TournamentList';
import { subSelectStyles } from '../../styles/selectStyles';
import { colors } from '../../styles/colors';

export const Overview = () => {
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [tournamentLoading, setTournamentLoading] = useState<boolean>(false);
  const [stageLoading, setStageLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [stages, setStages] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedTournament, setSelectedTournament] = useState<any>(null);
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [shouldFetchTournaments, setShouldFetchTournaments] = useState<boolean>(false);
  const [shouldFetchStages, setShouldFetchStages] = useState<boolean>(false);
  const [matchLoading, setMatchLoading] = useState<boolean>(false);
  const [matches, setMatches] = useState<any[]>([]);
  const [shouldFetchMatches, setShouldFetchMatches] = useState<boolean>(false);
  const [isDefaultSelection, setIsDefaultSelection] = useState<boolean>(true);
  const [forceFetchStages, setForceFetchStages] = useState<boolean>(false);
  const [forceFetchMatches, setForceFetchMatches] = useState<boolean>(false);

  const onSelectCountry = (option: any) => {
    setSelectedCountry(countries.find(country => country.id === option.value));
    setIsDefaultSelection(false);
    setTournaments([]);
    setStages([]);
    setMatches([]);
    setSelectedTournament(null);
    setSelectedStage(null);
    setShouldFetchTournaments(true);
    setIsDefaultSelection(false);
  };

  const onSelectTournament = (tournamentId: number) => {
    setSelectedTournament(tournaments.find(tournament => tournament.id === tournamentId));
    setIsDefaultSelection(false);
    setStages([]);
    setSelectedStage(null);
    setShouldFetchStages(true);
    setMatches([]);
  };

  const onSelectStage = (stageId: number) => {
    setIsDefaultSelection(false);
    setSelectedStage(stages.find(stage => stage.id === stageId));
    setMatches([]);
    setShouldFetchMatches(true);
  };

  const onForceFetchStage = () => {
    setIsDefaultSelection(false);
    setMatches([]);
    setStages([]);
    setSelectedStage(null);
    setForceFetchStages(true);
  };

  const onForceFetchMatch = () => {
    setIsDefaultSelection(false);
    setMatches([]);
    setForceFetchMatches(true);
  };

  const fetchCountries = async () => {
    setCountryLoading(true);
    try {
      const res = await fetch('/api/countries');
      const data = await res.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
      setCountries([]);
    }

    setCountryLoading(false);
  };

  const fetchTournaments = async (countryId: number) => {
    setTournamentLoading(true);
    try {
      const res = await fetch(`/api/tournaments/${countryId}`);
      setShouldFetchTournaments(false);
      const data = await res.json();
      setTournaments(data);
    } catch (error) {
      setShouldFetchTournaments(false);
      console.error(error);
      setTournaments([]);
    }
    setTournamentLoading(false);
  };

  const fetchStages = async (tournamentId: number, force = false) => {
    setStageLoading(true);
    try {
      const res = await fetch(`/api/stages/${tournamentId}${force ? '?force=true' : ''}`);
      setShouldFetchStages(false);
      setForceFetchStages(false);
      const data = await res.json();
      setStages(data);
    } catch (error) {
      setShouldFetchStages(false);
      setForceFetchStages(false);
      console.error(error);
      setStages([]);
    }
    setStageLoading(false);
  };

  const fetchMatches = async (stageId: number, force = false) => {
    setMatchLoading(true);
    try {
      const res = await fetch(`/api/stageMatches/${stageId}${force ? '?force=true' : ''}`);
      setShouldFetchMatches(false);
      setForceFetchMatches(false);
      const data = await res.json();
      setMatches(data);
    } catch (error) {
      setShouldFetchMatches(false);
      setForceFetchMatches(false);
      console.error(error);
      setMatches([]);
    }
    setMatchLoading(false);
  };

  useEffect(() => {
    fetchCountries().then();
  }, []);

  useEffect(() => {
    function setDefaultTournament() {
      setSelectedTournament(
        tournaments.find(tournament => tournament.id === DEFAULT_SELECT.tournamentId)
      );
      setShouldFetchStages(true);
    }

    function setDefaultStage() {
      setSelectedStage(stages.find(stage => stage.id === DEFAULT_SELECT.stageId));
      setShouldFetchMatches(true);
    }

    if (shouldFetchTournaments && selectedCountry) {
      fetchTournaments(selectedCountry.id).then();
    }
    if (selectedTournament && (shouldFetchStages || forceFetchStages)) {
      fetchStages(selectedTournament.id, forceFetchStages).then();
    }
    if (selectedStage && (shouldFetchMatches || forceFetchMatches)) {
      fetchMatches(selectedStage.id, forceFetchMatches).then();
    }
    if (countries && countries.length > 0 && !selectedCountry && isDefaultSelection) {
      setSelectedCountry(countries.find(country => country.id === DEFAULT_SELECT.countryId));
      setShouldFetchTournaments(true);
    }
    if (tournaments.length > 0 && !selectedTournament && isDefaultSelection) {
      setDefaultTournament();
    }
    if (stages.length > 0 && !selectedStage && isDefaultSelection) {
      setDefaultStage();
    }
  }, [
    shouldFetchTournaments,
    selectedCountry,
    selectedTournament,
    shouldFetchStages,
    shouldFetchMatches,
    selectedStage,
    countries,
    tournaments,
    stages,
    isDefaultSelection,
    forceFetchStages,
    forceFetchMatches
  ]);

  const tournamentLevelGroup = groupBy(tournaments, 'level');
  const levelTournaments = Object.keys(tournamentLevelGroup).map(level => {
    return {
      level,
      tournaments: tournamentLevelGroup[level]
    };
  });

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem w="100%">
          <FormControl>
            <FormLabel>Select Country</FormLabel>
            <ReactSelect
              styles={subSelectStyles}
              placeholder="Select country"
              defaultValue={
                selectedCountry && { label: selectedCountry?.name, value: selectedCountry?.id }
              }
              options={countries.map(country => ({
                label: country?.name,
                value: country?.id
              }))}
              value={
                selectedCountry && { label: selectedCountry?.name, value: selectedCountry?.id }
              }
              onChange={onSelectCountry}
              isLoading={countryLoading}
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={2}
        mt="10"
        bg="white"
        border="2px solid"
        borderColor="gray.200"
        minHeight={'1000'}
      >
        <GridItem colSpan={3} borderRight={'1px'} borderColor={colors.ntbOrange} p="5">
          <TournamentList
            label="Tournaments"
            isLoading={shouldFetchTournaments || tournamentLoading}
            levels={levelTournaments}
            onSelect={onSelectTournament}
            selectedTournament={selectedTournament}
            onForceStage={onForceFetchStage}
            stageLoading={forceFetchStages && stageLoading}
          />
        </GridItem>
        <GridItem colSpan={3} borderRight={'1px'} borderColor={colors.ntbOrange} p="5">
          <StageList
            label="Stages"
            stages={stages}
            isLoading={shouldFetchStages || stageLoading}
            onSelectStage={onSelectStage}
            selectedStage={selectedStage}
            onForceMatch={onForceFetchMatch}
            matchLoading={forceFetchMatches && matchLoading}
          />
        </GridItem>
        <GridItem colSpan={6} borderColor={colors.ntbOrange} p="5">
          <MatchList label="Matches" matches={matches} isLoading={matchLoading} />
        </GridItem>
      </Grid>
    </>
  );
};
