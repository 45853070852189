import {
  Card,
  CardBody,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { EVENT_TYPE_MAP } from '../../../utils/constants';

interface MatchEventsProps {
  events: any[];
}

export const MatchEvents = ({ events }: MatchEventsProps): JSX.Element => {
  return (
    <>
      <SimpleGrid spacing={4} templateColumns="repeat(auto-fill)">
        <Card>
          <CardBody>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Id</Th>
                    <Th>Time</Th>
                    <Th>Sort</Th>
                    <Th>Type</Th>
                    <Th>Comment</Th>
                    <Th>Person</Th>
                    <Th>Person2</Th>
                    <Th>Team</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {events.map((event: any, index: number) => (
                    <Tr key={event.id}>
                      <Td>{event.id}</Td>
                      <Td>{event.time}</Td>
                      <Td>{event.sorting}</Td>
                      <Td>
                        {event.matchEventTypeId ? `${EVENT_TYPE_MAP[event.matchEventTypeId]}` : ''}
                      </Td>
                      <Td>{event?.comment}</Td>
                      <Td>{event?.person?.name}</Td>
                      <Td>{event?.person2?.name}</Td>
                      <Td>{event?.team?.name}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </SimpleGrid>
    </>
  );
};
