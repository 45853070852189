import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { colors } from '../styles/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const baseStyle = definePartsStyle({
  field: {
    color: `${colors.darkGrey}`,
    border: '1px solid',
    borderColor: `${colors.lightGrey}`,
    backgroundColor: `${colors.light}`,
    borderRadius: '2px',
    _hover: {
      borderColor: `${colors.ntbOrangeLight}`
    },
    _focus: {
      borderColor: `${colors.ntbOrange}`,
      outline: 'none'
    }
  }
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
