import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { colors } from '../styles/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  control: {
    _hover: {
      backgroundColor: 'orange.50'
    },
    borderColor: `${colors.ntbOrange}`
  }
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
