import { Routes, Route } from 'react-router-dom';
import { Overview } from '../Overview/Overview';
import { MatchUpdate } from '../Match/VersionUpdate/Update';
import { MatchDetails } from '../Match/Details/MatchDetails';
import SidebarWithHeader from '../SideBar/SideBar';

const Admin = () => {
  return (
    <>
      <SidebarWithHeader>
        <Routes>
          <Route path="/overview" element={<Overview />} />
          <Route path="/update" element={<MatchUpdate />} />
          <Route path="/matches/:matchId" element={<MatchDetails />} />
        </Routes>
      </SidebarWithHeader>
    </>
  );
};

export default Admin;
