import React from 'react';
import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';
import { NavLink as ReactLink } from 'react-router-dom';

import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { colors } from '../../styles/colors';

interface NavItemProps extends FlexProps {
  icon: IconType;
  route: string;
  children: ReactText;
}
const MenuLinkItem = ({ icon, route, children, ...rest }: NavItemProps) => {
  return (
    <Link
      as={ReactLink}
      to={route}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: colors.ntbOrange,
          color: 'white'
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white'
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

export default MenuLinkItem;
