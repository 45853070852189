import { colors } from './colors';

export const subSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '2px',
    borderColor: state.isFocused ? `${colors.ntbOrange}` : `${colors.lightGrey}`,
    '&:hover': {
      borderColor: `${colors.ntbOrange}`
    },
    boxShadow: 'none',
    height: '40px'
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    padding: '3px !important',
    '&:hover': {
      backgroundColor: state.isSelected ? `${colors.ntbOrange}` : `${colors.ntbOrangeLight}`
    },
    backgroundColor: state.isSelected ? `${colors.ntbOrange}` : 'none',
    color: state.isSelected ? `${colors.light}` : `${colors.darkGrey}`
  })
};

const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    minWidth: '200px',
    minHeight: '40px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '8px !important',
    fontSize: '0.875rem',
    fontFamily: 'Roboto'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '2px',
    borderColor: state.isFocused ? `${colors.ntbOrange}` : `${colors.lightGrey}`,
    '&:hover': {
      borderColor: `${colors.ntbOrangeLight}`
    },
    boxShadow: 'none',
    minHeight: '40px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    marginTop: '5px',
    marginBottom: '5px',
    borderRadius: '2px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '3px !important',
    '&:hover': {
      backgroundColor: state.isSelected ? `${colors.cercisePurple}` : `${colors.cercisePurpleLight}`
    },
    backgroundColor: state.isDisabled
      ? 'none'
      : state.isSelected
      ? `${colors.cercisePurple}`
      : state.isFocused
      ? `${colors.cercisePurpleLight}`
      : 'none',
    color: state.isDisabled
      ? 'none'
      : state.isSelected
      ? `${colors.light}`
      : state.isFocused
      ? `${colors.darkGrey}`
      : `${colors.darkGrey}`
  })
};

export default selectStyles;
