import { Text, Tab, TabList, TabPanel, TabPanels, Tabs, Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import PageHeader from '../../../elements/PageHeader/PageHeader';
import { MatchInfo } from './MatchInfo';
import { MatchEvents } from './MatchEvents';
import { MatchVersions } from './MatchVersions';

export const MatchDetails = () => {
  const { matchId } = useParams();
  const res = useFetch(`/api/matches/${matchId}`, { method: 'GET' });

  if (res.error) return <Text fontSize="4xl">Error getting match details.</Text>;

  if (!res.response) {
    return <Spinner color="red.500" />;
  }
  const typedData: any = res.response;

  return (
    <>
      <PageHeader
        name={typedData?.name}
        timestamp={typedData?.timestamp}
        stageName={typedData?.stage?.fullName}
      />
      <Tabs marginTop={5}>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Events</Tab>
          <Tab>Versions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MatchInfo matchData={typedData} />
          </TabPanel>
          <TabPanel>
            <MatchEvents events={typedData?.events} />
          </TabPanel>
          <TabPanel>
            <MatchVersions versions={typedData?.versions} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
