import React from 'react';
import { StyledPageHeader, PageName, DateInfo, Title, Info } from './StyledPageHeader';
import { format } from 'date-fns';

const PageHeader = ({ name, timestamp, stageName }: any) => {
  const formattedDate = timestamp ? format(new Date(timestamp), 'dd/MM-yyyy HH:mm:ss') : null;

  return (
    <>
      <StyledPageHeader>
        <PageName>
          {name && <Title>{name}</Title>}
          {formattedDate && <DateInfo>{formattedDate}&nbsp;</DateInfo>}
          {stageName && <Info>{stageName}</Info>}
        </PageName>
      </StyledPageHeader>
    </>
  );
};

export default PageHeader;
