import { useState, useEffect } from 'react';
import { FormControl, Table, TableContainer, Tbody, Tag, Box } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { MatchListHeader } from './MatchListHeader';
import { MatchRow } from './MatchRow';

interface MatchTableListProps {
  label: string;
  matches: Array<any>;
  isLoading: boolean;
}

export const MatchList = ({ label, matches, isLoading }: MatchTableListProps): JSX.Element => {
  const [teamName, setTeamName] = useState<string>('');
  const [filteredMatches, setFilteredMatches] = useState<any[]>([]);
  const [played, setPlayed] = useState<boolean>(false);
  const [orderedDayMatches, setOrderedDayMatches] = useState<any[]>([]);
  const [ascSorted, setASCSorted] = useState<boolean>(false);

  useEffect(() => {
    setFilteredMatches(matches);
    setPlayed(false);
    setTeamName('');
    setASCSorted(false);
  }, [matches]);

  useEffect(() => {
    if (filteredMatches && filteredMatches.length > 0) {
      const matchDayGroups = filteredMatches.reduce((groups, game) => {
        const date = game.timestamp.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});
      const dayMatches = Object.keys(matchDayGroups).map(date => {
        return {
          date,
          games: matchDayGroups[date]
        };
      });
      if (ascSorted) {
        setOrderedDayMatches(orderBy(dayMatches, ['date'], ['asc']));
      } else {
        setOrderedDayMatches(orderBy(dayMatches, ['date'], ['desc']));
      }
    } else {
      setOrderedDayMatches([]);
    }
  }, [filteredMatches, ascSorted]);

  const onInputChange = (event: any) => setTeamName(event.target.value);

  const onBlur = () => {
    setFilteredMatches(
      matches.filter((match: any) => {
        return match.name.includes(teamName);
      })
    );
  };
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      setFilteredMatches(
        matches.filter((match: any) => {
          return match.name.includes(teamName);
        })
      );
    }
  };

  const onStatusChange = (event: any) => {
    const { checked } = event.target;
    setPlayed(checked);
    setFilteredMatches(
      matches.filter((match: any) => {
        if (checked) {
          return match.matchStatusId === 1;
        }
        return true;
      })
    );
  };

  const onSortChange = (event: any) => {
    const { checked } = event.target;
    setASCSorted(checked);
  };

  return (
    <>
      <FormControl>
        <MatchListHeader
          label={label}
          matches={matches}
          played={played}
          sorted={ascSorted}
          onStatusChecked={onStatusChange}
          onSortChecked={onSortChange}
          teamName={teamName}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onTeamNameChange={onInputChange}
        />

        {isLoading ? (
          <Skeleton>
            <div>contents wrapped</div>
            <div>won't be visible</div>
          </Skeleton>
        ) : (
          <Box borderTop={'1px'} borderColor="#FF9900" pt={5}>
            {orderedDayMatches.map((dayMatch: any, index: number) => (
              <Box key={`table${index}`}>
                <Tag size={'md'} key={`date${index}`} variant="solid" colorScheme="orange">
                  {moment(dayMatch.date).format('D. MMM YYYY')}
                </Tag>

                <TableContainer mt={2} pb={5}>
                  <Table variant="striped" colorScheme="blackAlpha">
                    <Tbody>
                      {dayMatch.games.map((match: any, index: number) => (
                        <MatchRow index={index} match={match} key={`row${index}`} />
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </Box>
        )}
      </FormControl>
    </>
  );
};
