import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const PageHeaderContainer = styled.div`
  display: flex;
  overflow: hidden;
  line-height: 100%;
  align-self: flex-start;
  flex-direction: row;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 5px;
    height: 20px;
    color: #323232;

    :hover {
      color: ${colors.lightGrey};
    }
  }
`;

export const PageName = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.darkGrey};

  a {
    padding-right: 10px;
  }
`;

export const DateInfo = styled.span`
  //   font-weight: 400;
  //   font-size: 12px;
  color: white;
  border: 1px solid ${colors.darkGrey};
  border-radius: 2px;
  background-color: ${colors.darkGrey};
  //padding: 2px;
`;

export const Title = styled.div`
  //font-size: 18px;

  &:after {
    content: '|';
    margin-left: 10px;
    margin-right: 10px;
    color: ${colors.ntbOrange};
  }
`;

export const Info = styled.div`
  //   font-size: 16px;
  //   font-weight: 500;
  display: flex;
  flex-direction: row;
  a {
    padding-right: 0px;
  }

  &:before {
    content: '|';
    margin-left: 10px;
    margin-right: 10px;
    color: ${colors.ntbOrange};
  }
`;

export const StyledPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: row;
`;

export const StyledPageHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    position: relative;
    padding-right: 7px;
  }

  .searchAndHelp {
    display: flex;
    align-items: center;
  }
`;
