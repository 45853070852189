import { FormControl, List, ListIcon, ListItem, Link } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { Skeleton } from '@chakra-ui/react';
import { colors } from '../../styles/colors';
import { ListHeader } from './ListHeader';

interface StageListProps {
  label: string;
  stages: any[];
  isLoading: boolean;
  onSelectStage: Function;
  selectedStage: any;
  onForceMatch: Function;
  matchLoading: boolean;
}

export const StageList = ({
  label,
  stages,
  isLoading,
  onSelectStage,
  selectedStage,
  matchLoading,
  onForceMatch
}: StageListProps): JSX.Element => {
  return (
    <>
      <FormControl>
        <ListHeader
          label={label}
          selectedItem={selectedStage}
          onForce={onForceMatch}
          isLoading={matchLoading}
          forceButtonText="Fetch Matches"
        />

        {isLoading ? (
          <Skeleton>
            <div>contents wrapped</div>
            <div>won't be visible</div>
          </Skeleton>
        ) : (
          <List spacing={3} borderTop={'1px'} borderColor={colors.ntbOrange} pt={5}>
            {stages.map((stage, index) => (
              <ListItem key={stage.id}>
                <ListIcon
                  as={MdCheckCircle}
                  color={
                    selectedStage && selectedStage?.id === stage?.id
                      ? `${colors.ntbOrange}`
                      : 'grey'
                  }
                />
                <Link color="brand.500" onClick={() => onSelectStage(stage.id)}>
                  {stage.fullName}
                </Link>
              </ListItem>
            ))}
          </List>
        )}
      </FormControl>
    </>
  );
};
