export const colors = {
  ntbOrange: '#FF9900',
  ntbOrangeLight: '#FFCB7E',
  emeraldGreen: '#30826A',
  darkGrey: '#323232',
  darkGrey2: '#767676',
  grey: '#B0B6B9',
  lightGrey: '#E5E5E5',
  lightGrey2: '#F7F8F9',
  lightGrey3: '#EFF1F4',
  light: '#FFF',
  cercisePurple: '#5F1031',
  cercisePurpleLight: '#D099B0',
  cercisePurpleLight2: '#F9F3F5',
  cercisePurpleLight3: '#FEF9FB',
  redAlert: '#D83000'
};
