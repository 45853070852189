import { FormControl, List, ListIcon, ListItem, Link, Tag, Box } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { Skeleton } from '@chakra-ui/react';
import { colors } from '../../styles/colors';
import { ListHeader } from './ListHeader';

interface TournamentListProps {
  label: string;
  levels: Array<any>;
  isLoading: boolean;
  onSelect: Function;
  selectedTournament: any;
  onForceStage: Function;
  stageLoading: boolean;
}

export const TournamentList = ({
  label,
  levels,
  isLoading,
  onSelect,
  selectedTournament,
  onForceStage,
  stageLoading
}: TournamentListProps): JSX.Element => {
  return (
    <>
      <FormControl>
        <ListHeader
          label={label}
          selectedItem={selectedTournament}
          onForce={onForceStage}
          isLoading={stageLoading}
          forceButtonText="Fetch Stages"
        />
        {isLoading ? (
          <Skeleton>
            <div>contents wrapped</div>
            <div>won't be visible</div>
          </Skeleton>
        ) : (
          <Box borderTop={'1px'} borderColor={colors.ntbOrange}>
            {levels.map((level: any, index: number) => (
              <Box key={`level${index}`} pt={5}>
                <Tag size={'md'} variant="solid" colorScheme="orange">
                  Level: {level.level}
                </Tag>
                <List spacing={3} pt={5}>
                  {level?.tournaments.map((tournament: any, index: number) => (
                    <ListItem key={tournament.id}>
                      <ListIcon
                        as={MdCheckCircle}
                        color={
                          selectedTournament && selectedTournament?.id === tournament?.id
                            ? `${colors.ntbOrange}`
                            : 'grey'
                        }
                      />
                      <Link color="brand.500" onClick={() => onSelect(tournament.id)}>
                        {tournament.name} - {tournament.gender}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        )}
      </FormControl>
    </>
  );
};
