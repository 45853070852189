import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';

interface ListHeaderProps {
  label: string;
  selectedItem: any;
  onForce: Function;
  isLoading: boolean;
  forceButtonText: string;
}

export const ListHeader = ({
  label,
  selectedItem,
  isLoading,
  onForce,
  forceButtonText
}: ListHeaderProps): JSX.Element => {
  return (
    <>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box p="2">
          <Text size="md">{label}</Text>
        </Box>
        <Spacer />
        <Button
          size="xs"
          isLoading={isLoading}
          loadingText="Fetching"
          variant="outline"
          onClick={() => {
            onForce();
          }}
          isDisabled={!selectedItem}
        >
          {forceButtonText}
        </Button>
      </Flex>
    </>
  );
};
