const MATCH_STATUS_MAP: any = {
  1: 'Played',
  2: 'Not started (Also "result unknown")',
  3: 'Postponed',
  4: 'Abandoned',
  5: 'Will not be played',
  6: 'Date not set',
  7: 'Ongoing',
  8: 'First half',
  9: 'Half time',
  10: 'Second half',
  11: 'First half extra time',
  12: 'Second half extra time',
  13: 'Penalty shootout',
  14: 'Pause before extra time',
  15: 'Half time extra time',
  16: 'Played, but cancelled',
  17: 'Played, but not counting in statistics',
  18: 'Second half time extra time',
  19: 'Third half extra time',
  20: 'Third half time extra time',
  21: 'Fourth half extra time',
  22: 'Third half',
  23: 'Fourth half time extra time',
  24: 'Fifth half extra time',
  25: 'Fifth half time extra time',
  26: 'Sixth half extra time',
  27: 'Sixth half time extra time',
  28: 'Seventh half extra time',
  29: 'Seventh half time extra time',
  30: 'Eighth half extra time',
  31: 'Second half time'
};

const EVENT_TYPE_MAP: any = {
  1: 'Comment',
  2: 'Goal',
  3: 'Red card',
  4: 'Yellow card',
  5: 'Assist - Always have the same "sorting" as a goal event',
  6: 'Offside',
  8: 'Own goal - Own goals are not connected to the team that conceded the goal although the player is on the conceding team.',
  9: 'Penalty',
  10: 'Penalty goal - May have the same "sorting" as a penalty event',
  11: 'Penalty miss - May have the same "sorting" as a penalty event',
  12: 'Disallowed goal',
  14: 'Match kicks off',
  15: 'Half time',
  16: 'Match ends',
  17: 'Second half ends and extra time will be played (could also go directly to penalties). Either 16 or 17 are used in a match, not both',
  18: 'Half time extra time',
  19: 'Penalties shootout',
  20: 'End second half extra time',
  23: 'Player substituted',
  24: 'Substitute on - Always have the same "sorting" as a "Player substituted" event',
  25: 'Corner',
  26: 'Start second half',
  27: 'Corner goal - May have the same "sorting" as a corner event',
  28: 'Start first half extra time',
  29: 'Start second half extra time',
  30: 'Goal penalties shootout - May have the same "sorting" as a "Penalty penalties shootout" event',
  31: 'Miss penalties shootout - May have the same "sorting" as a "Penalty penalties shootout" event',
  32: 'Penalty penalties shootout',
  41: 'Free kick',
  43: 'Lineup ready',
  44: 'Withdrawn red card',
  45: 'Withdrawn yellow card',
  46: 'Withdrawn second yellow card',
  47: 'Second yellow card changed to first yellow card (The first yellow card has been withdrawn)',
  48: 'Indirect assist',
  49: 'TV 2 video event',
  100: 'Two minutes suspension',
  101: 'Five minutes suspension',
  102: 'Ten minutes suspension',
  103: 'Two minutes suspension and penalty',
  104: 'Second half time extra time',
  105: 'Start third half extra time',
  106: 'Third half time extra time',
  107: 'Start fourth half time extra time',
  108: 'Shot',
  109: 'Face off',
  110: 'Second half time',
  111: 'Start third half',
  112: 'Blue card',
  113: 'Match penalty - 25 minutes',
  114: 'Fourth half time extra time',
  115: 'Start fifth half time extra time',
  116: 'Fifth half time extra time',
  117: 'Start sixth half time extra time',
  118: 'Sixth half time extra time',
  119: 'Start seventh half time extra time',
  120: 'Seventh half time extra time',
  121: 'Start eighth half time extra time',
  123: 'VAR',
  124: 'Offence',
  125: 'Fouled - Always have the same "sorting" as an offence event',
  126: 'Injury Time - The comment will hold the number of minutes added on by the officials',
  127: 'Annulled penalty kick - When the penalty kick has to be retaken',
  128: 'Annulled penalty goal - Always have the same "sorting" as an annulled penalty event',
  129: 'Annulled penalty miss - Always have the same "sorting" as an annulled penalty event',
  130: 'Penalty save',
  131: 'Annulled penalty save'
};

export { MATCH_STATUS_MAP, EVENT_TYPE_MAP };
