import { extendTheme } from '@chakra-ui/react';
import { tagTheme } from './tagTheme';
import { buttonTheme } from './buttonTheme';
import { tabsTheme } from './tabTheme';
import { inputTheme } from './inputTheme';
import { checkboxTheme } from './checkBoxTheme';

export const theme = extendTheme({
  components: {
    Tag: tagTheme,
    Button: buttonTheme,
    Tabs: tabsTheme,
    Input: inputTheme,
    Checkbox: checkboxTheme
  }
});
