import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from '../styles/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

const baseStyle = definePartsStyle({
  tab: {
    borderTopRadius: 'lg',
    borderBottom: 'none',
    _selected: {
      bg: mode('#fff', 'gray.800'),
      color: mode(`${colors.ntbOrange}`, `${colors.ntbOrangeLight}`)
    }
  },
  tablist: {
    borderBottom: '2x solid',
    borderColor: 'inherit'
  },
  tabpanel: {
    border: '1px solid',
    borderColor: 'inherit',
    borderBottomRadius: 'lg',
    borderTopRightRadius: 'lg'
  }
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
