import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { colors } from '../styles/colors';

const outline = defineStyle({
  borderRadius: '3px',
  color: `${colors.ntbOrange}`,
  border: '1px solid',
  borderColor: `${colors.ntbOrange}`,
  _hover: {
    backgroundColor: 'orange.50'
  }
});

export const buttonTheme = defineStyleConfig({
  variants: { outline }
});
