import { Text, Box, Checkbox, Flex, Spacer, Input, CheckboxGroup } from '@chakra-ui/react';

interface MatchListProps {
  label: string;
  matches: Array<any>;
  played: boolean;
  sorted: boolean;
  onStatusChecked: Function;
  onSortChecked: Function;
  teamName: string;
  onKeyDown: Function;
  onBlur: Function;
  onTeamNameChange: Function;
}

export const MatchListHeader = ({
  label,
  matches,
  played,
  sorted,
  onStatusChecked,
  onSortChecked,
  teamName,
  onKeyDown,
  onBlur,
  onTeamNameChange
}: MatchListProps): JSX.Element => {
  return (
    <>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box p="2">
          <Text size="md">{label}</Text>
        </Box>
        <Spacer />
        <CheckboxGroup>
          <Checkbox
            isDisabled={!matches || matches.length === 0}
            colorScheme="orange"
            isChecked={played}
            onChange={e => {
              onStatusChecked(e);
            }}
          >
            Played
          </Checkbox>
          <Checkbox
            isDisabled={!matches || matches.length === 0}
            colorScheme="orange"
            isChecked={sorted}
            onChange={e => {
              onSortChecked(e);
            }}
          >
            Sort
          </Checkbox>
          <Input
            isDisabled={!matches || matches.length === 0}
            value={teamName}
            maxWidth={60}
            variant="any"
            placeholder="Filter by team name"
            size="sm"
            onKeyDown={e => onKeyDown(e)}
            onBlur={() => onBlur()}
            onChange={e => onTeamNameChange(e)}
          />
        </CheckboxGroup>
      </Flex>
    </>
  );
};
