import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { colors } from '../styles/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bg: `${colors.ntbOrange}`,
    color: 'blackAlpha.700'
  }
});

export const tagTheme = defineMultiStyleConfig({ baseStyle });
