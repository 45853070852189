import { Flex, useColorModeValue, FlexProps } from '@chakra-ui/react';

import AvatarMenu from './AvatarMenu';

interface HeaderProps extends FlexProps {
  onOpen: () => void;
}
const Header = ({ onOpen, ...rest }: HeaderProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <AvatarMenu />
    </Flex>
  );
};

export default Header;
