import { ReactNode } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  useDisclosure,
  BoxProps,
  Link,
  Image
} from '@chakra-ui/react';
import { FiHome, FiArrowUp } from 'react-icons/fi';
import { IconType } from 'react-icons';
import MenuLinkItem from './MenuLinkItem';
import Header from '../Header/Header';
import { Link as ReactLink } from 'react-router-dom';
import NTBLogo from './NTB.png';

interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Overview', icon: FiHome, route: '/overview' },
  { name: 'Update', icon: FiArrowUp, route: '/update' }
];

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
  const { onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Header onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <>
      <Box
        transition="3s ease"
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}
      >
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Link as={ReactLink} to="/" style={{ textDecoration: 'none' }}>
            <Flex align="center" p="4" mx="4" borderRadius="lg" role="group" cursor="pointer">
              <Image src={NTBLogo} alt="NTB" />
            </Flex>
          </Link>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map(link => (
          <MenuLinkItem key={link.name} icon={link.icon} route={link.route}>
            {link.name}
          </MenuLinkItem>
        ))}
      </Box>
    </>
  );
};
