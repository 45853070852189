import { Link as ReactLink } from 'react-router-dom';
import { Link, Td, Tr, Text, Center, Grid, GridItem, Button } from '@chakra-ui/react';
import moment from 'moment';
import { MATCH_STATUS_MAP } from '../../../utils/constants';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';

interface MatchRowProps {
  index: number;
  match: any;
}

export const MatchRow = ({ index, match }: MatchRowProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateVersion, setUpdateVersion] = useState(false);

  useEffect(() => {
    async function updateMatchVersion(matchId: number) {
      setIsLoading(true);

      const result = await fetch(`/api/matches/${matchId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (result.ok) {
        const response = await result.json();
        setUpdateVersion(false);
        Store.addNotification({
          message: `${response.message ? response.message : 'MatchId added to queue.'}`,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
      } else {
        setUpdateVersion(false);
        const response = await result.json();
        Store.addNotification({
          message: `${response.message ? response.message : 'MatchId added to queue.'}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
      }

      setIsLoading(false);
    }
    if (updateVersion) {
      updateMatchVersion(match.id).then();
    }
  }, [match.id, updateVersion]);

  const updateMatchVersion = () => {
    setUpdateVersion(true);
  };

  return (
    <>
      <Tr key={index + `${match.name}`}>
        <Td>
          <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(12, 1fr)">
            <GridItem rowSpan={1} colSpan={3}>
              <Text as="b" fontSize="lg">
                {moment(match.timestamp).format('HH:mm')}
              </Text>
            </GridItem>
            <GridItem rowSpan={1} colSpan={6}>
              <Center>
                <Link
                  as={ReactLink}
                  to={`/matches/${match.id}`}
                >{`${match.name}(${match.id})`}</Link>
              </Center>
            </GridItem>
            <GridItem rowSpan={1} colSpan={3}>
              <Center>
                {match.result
                  ? `${match.result.homeScore90 ? match.result.homeScore90 : ''} - ${
                      match.result.awayScore90 ? match.result.awayScore90 : ''
                    }`
                  : '-'}
              </Center>
            </GridItem>

            <GridItem rowSpan={1} colSpan={3}>
              {`Status: ${MATCH_STATUS_MAP[match.matchStatusId]}`}
            </GridItem>
            <GridItem rowSpan={1} colSpan={6}>
              <Center>
                <Button
                  size="xs"
                  isLoading={isLoading}
                  loadingText="Updating"
                  variant="outline"
                  onClick={updateMatchVersion}
                >
                  Update Version
                </Button>
              </Center>
            </GridItem>
            <GridItem rowSpan={1} colSpan={3}>
              <Center>Version: {match.version ? match.version : ''}</Center>
            </GridItem>
          </Grid>
        </Td>
      </Tr>
    </>
  );
};
