import React, { useState, useEffect } from 'react';
import { Store } from 'react-notifications-component';
import { Grid, GridItem, Button, Input } from '@chakra-ui/react';

export const MatchUpdate = () => {
  const [matchId, setMatchId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [updateVersion, setUpdateVersion] = useState(false);

  useEffect(() => {
    async function updateMatchVersion() {
      setIsLoading(true);

      const result = await fetch(`/api/matches/${matchId}?forceUpdate=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (result.ok) {
        const response = await result.json();
        setUpdateVersion(false);
        setMatchId('');
        Store.addNotification({
          message: `${response.message ? response.message : 'MatchId added to queue.'}`,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
      } else {
        setUpdateVersion(false);
        const response = await result.json();
        Store.addNotification({
          message: `${response.message ? response.message : 'MatchId added to queue.'}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
      }

      setIsLoading(false);
    }
    if (updateVersion) {
      updateMatchVersion().then();
    }
  }, [matchId, updateVersion]);

  const handleChange = (event: any) => setMatchId(event.target.value);
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      updateMatchVersion();
    }
  };

  const updateMatchVersion = () => {
    setUpdateVersion(true);
  };
  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem w="100%">
          <>
            <Input
              variant="any"
              placeholder="Match Id"
              name="externalId"
              value={matchId}
              onChange={handleChange}
              onKeyDown={onKeyDown}
            />
          </>
        </GridItem>
        <GridItem w="100%">
          <Button
            isLoading={isLoading}
            loadingText="Updating"
            variant="outline"
            onClick={updateMatchVersion}
          >
            Update Version
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};
