import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import { ChakraProvider } from '@chakra-ui/react';
import Admin from './components/Admin/Admin';
import { theme } from './customThemes/theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReactNotifications />
      <Router>
        <Admin />
      </Router>
    </ChakraProvider>
  );
}

export default App;
