import {
  Box,
  Text,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { MATCH_STATUS_MAP } from '../../../utils/constants';

interface MatchInfoProps {
  matchData: any;
}

export const MatchInfo = ({ matchData }: MatchInfoProps): JSX.Element => {
  return (
    <>
      <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(400px, 1fr))">
        <Card>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  General
                </Heading>
                <Text pt="2" fontSize="sm">
                  Name: {matchData.name}
                </Text>
                <Text pt="2" fontSize="sm">
                  Timestamp: {matchData.timestamp}
                </Text>
                <Text pt="2" fontSize="sm">
                  Status: {MATCH_STATUS_MAP[matchData.status]}
                </Text>
                <Text pt="2" fontSize="sm">
                  Result:{' '}
                  {matchData.result
                    ? `${matchData.result.homeScore90 ? matchData.result.homeScore90 : ''} - ${
                        matchData.result.awayScore90 ? matchData.result.awayScore90 : ''
                      }`
                    : '-'}
                </Text>
                <Text pt="2" fontSize="sm">
                  LastUpdated: {matchData.last_updated}
                </Text>
              </Box>
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Meta
                </Heading>
                <Text pt="2" fontSize="sm">
                  Round: {matchData.round}
                </Text>
                <Text pt="2" fontSize="sm">
                  Length: {matchData.length}
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </SimpleGrid>
    </>
  );
};
