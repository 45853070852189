import { useState, useEffect } from 'react';

const useFetch = (url: string, { method }: any) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const res = await fetch(url, { method });
        const json = await res.json();
        setResponse(json);
      } catch (error: any) {
        setError(error);
      }
    };
    FetchData().then();
  }, [url, method]);
  return { response, error };
};

export default useFetch;
